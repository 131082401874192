.wish_badge {
  background-color: var(--primary);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 1rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  border-radius: 100%;
}
