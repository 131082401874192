.cart_button {
    position: relative;
    z-index: 1;

    .quick_cart_mini {
        top: 100%;
        right: -16px;
        opacity: 0;
        visibility: hidden;
        position: absolute;

        &::before {
            content: '';
            position: absolute;
            top: -14px;
            right: 28px;
            border: 8px solid transparent;
            border-bottom-color: var(--secondary);
        }

        &::after {
            content: '';
            position: absolute;
            height: 8px;
            top: -8px;
            right: 15px;
            background: transparent;
            width: 41px;
        }
    }

    &:hover {
        .quick_cart_mini {
            opacity: 1;
            visibility: visible;
        }
    }
}

.cart_badge {
    background-color: var(--primary);
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    border-radius: 100%;
}
